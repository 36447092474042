html,body {
  background-color: #ececec!important;
}

.event-cell-thumbnail, .user-cell-thumbnail, .notification-cell-thumbnail, .organization-cell-thumbnail {
  object-fit: cover;
  width: 8em;
  height: 8em;
  float: right;
  border-radius: 10px;
}

.event-cell-about {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #888888;
}

.card-img-top {
  object-fit: cover;
  //width: 45em;
  height: 24em;
}

.housing {
  overflow-x: hidden;
  overflow-y: scroll;
}

.edit-pic-label {
  float: right;
  margin: 1.5em;
  margin-bottom: 0.5em;
}


#profilePic, #coverPhoto { /*for EditEventInfo*/
  display: none;
}

.card {
  border-radius: 10px!important;
  margin-bottom: 0.5em;
}
.card-img-top {
  border-top-left-radius: 9px!important;
  border-top-right-radius: 9px!important;
}

.SearchHeader {
  margin-top: 2em;
  font-weight: bold;
}
.SearchHeader.top {
  margin-top: 0.5em;
}

.btn-group .btn {
  border-radius: 10px;
}

.list-message {
  margin: 0.5em;
}

.MyEventsHeader {
  padding: 0.5em;
  background-color: lightgray;
}

#main-container {
  margin-top: 6em;
  margin-bottom: 2em;
  margin-right: 2em;
  margin-left: 2em;
  //max-width: 750px;
}

.header-pic {
  height:4.5em;
}

.header-profile-pic {
  height:2.5em;
  border-width: thin;
  border-color: white;
  border-radius: 50%;
}

.fa, .fas, .far {
  line-height: 1.5!important;
}

a.noDec {
  text-decoration: none;
  color:inherit!important;
}

.overlay {
  z-index: 99999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}

.Header {
  background-color: black;
  color: white;
}

.HeaderAppendix {
  background-color: #111111;
}

.VegaLink-noStyling {
  cursor: pointer;
}

.event-cell-title {
  font-weight: bold;
}

.event-info-title {
  font-weight: bold;
}

.time {
  color: #0040F5;
}

.event-cell-info {
  color: black;
}

.VegaLink {
  color: inherit;
  cursor: pointer;
}
.VegaLink:hover, .VegaLink.active {
  color: #6acea1 !important;
}
.NavList .VegaLink {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

#searchbar {
  margin-bottom: 0;
}
#searchbar > input {
  color: inherit;
  background-color: inherit;
  border-radius: 1em;
}
#searchbar > input:focus {
  border-color: #6acea1;
  box-shadow: none;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem!important;
  text-align: center;
  pointer-events: none;
}

.fixed {
  position: fixed!important;
}

.sort-button {
  color: #888888;
  cursor: pointer;
}
.sort-button:hover,.sort-button.active {
  color: #6acea1;
}
.sort-button.active {
  text-decoration: underline;
}

.form-control.vega-form-control, .form-control.vega-form-control[readonly] {
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #444444;
  margin-bottom: 1px;
  background-color: #fafafa;
  outline: none!important;
  box-shadow: none!important;
}

.form-control.vega-form-control:focus {
  border-bottom: 3px solid #6acea1;
  background-color: #f6ffff;
}

.vega-form-control.error {
  border-bottom: 3px solid #ff0000!important;
  //background-color: #f6ffff;
}

.vega-form-group {
  margin-bottom: 1.5em;
}

.input-range__label-container { /*Above the slider*/
  display: none!important;
  //font-family: sans-serif!important;
}
.input-range__label--min, .input-range__label--max {
  display: none!important;
}
.input-range__slider {
  background: #6acea1;
  border: 1px solid #6acea1;
}
.input-range__track--active {
  background: #6acea1;
}

.profile-pic {
  display: block;
  margin: auto;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.NotificationCell.unread {
  background-color: #baeed1;
}

.modal {
  z-index: 99999!important;
  background-color: #44444488;
  display: block!important;
}
.modal-content {
  border-radius: 10px!important;
}

.VegaTextBox-error {
  color: #ff0000;
  font-size: 10px;
}

.form-error {
  color: #ff0000;
}

.signup-vega-logo {
  margin: 1em;
  width: 12vw;
  height: 12vw;
}

.login-vega-logo {
  margin: 1em;
  width: 24vw;
}

.anon-search {
  //width: 20vw;
}

.DiscoverySettings-label {
  margin-bottom: 0.5em;
}

.under_construction_img {
  width: 20vw;
}

.left-thumbnail {
  float:left;
  margin-right: 1em;
}

.list-group-item:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.list-group-item:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.list-group-item.permission {
  cursor: pointer;
}

.list-group-item.permission.inactive {
  background-color: #dddddd;
}

.list-group {
  margin-bottom: 0.75em;
}

.LocationPickerModal {
  width:100%;
  height:80vh;
}

.modal-dialog {
  //max-width: none!important;
  margin:auto;
}
