/* btn-vega-light */
.btn-vega-light {
  color: #FFFFFF;
  background-color: #6ACEA1;
  border-color: #FFFFFF;
}

.btn-vega-light:hover,
.btn-vega-light:focus,
.btn-vega-light:active,
.btn-vega-light.active,
.open .dropdown-toggle.btn-vega-light {
  color: #FFFFFF;
  background-color: #4C9675;
  border-color: #FFFFFF;
}

.btn-vega-light:active,
.btn-vega-light.active,
.open .dropdown-toggle.btn-vega-light {
  background-image: none;
}

.btn-vega-light.disabled,
.btn-vega-light[disabled],
fieldset[disabled] .btn-vega-light,
.btn-vega-light.disabled:hover,
.btn-vega-light[disabled]:hover,
fieldset[disabled] .btn-vega-light:hover,
.btn-vega-light.disabled:focus,
.btn-vega-light[disabled]:focus,
fieldset[disabled] .btn-vega-light:focus,
.btn-vega-light.disabled:active,
.btn-vega-light[disabled]:active,
fieldset[disabled] .btn-vega-light:active,
.btn-vega-light.disabled.active,
.btn-vega-light[disabled].active,
fieldset[disabled] .btn-vega-light.active {
  background-color: #6ACEA1;
  border-color: #FFFFFF;
}

.btn-vega-light .badge {
  color: #6ACEA1;
  background-color: #FFFFFF;
}


/* btn-vega-dark */

.btn-vega-dark {
  color: #6ACEA1;
  background-color: #444444;
  border-color: #6ACEA1;
}

.btn-vega-dark:hover,
.btn-vega-dark:focus,
.btn-vega-dark:active,
.btn-vega-dark.active,
.open .dropdown-toggle.btn-vega-dark {
  color: #6ACEA1;
  background-color: #222;
  border-color: #6ACEA1;
}

.btn-vega-dark:active,
.btn-vega-dark.active,
.open .dropdown-toggle.btn-vega-dark {
  background-image: none;
}

.btn-vega-dark.disabled,
.btn-vega-dark[disabled],
fieldset[disabled] .btn-vega-dark,
.btn-vega-dark.disabled:hover,
.btn-vega-dark[disabled]:hover,
fieldset[disabled] .btn-vega-dark:hover,
.btn-vega-dark.disabled:focus,
.btn-vega-dark[disabled]:focus,
fieldset[disabled] .btn-vega-dark:focus,
.btn-vega-dark.disabled:active,
.btn-vega-dark[disabled]:active,
fieldset[disabled] .btn-vega-dark:active,
.btn-vega-dark.disabled.active,
.btn-vega-dark[disabled].active,
fieldset[disabled] .btn-vega-dark.active {
  background-color: #444444;
  border-color: #6ACEA1;
}

.btn-vega-dark .badge {
  color: #444444;
  background-color: #6ACEA1;
}
