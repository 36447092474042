body {
  background-color: white;
  font-size: 5vw;
}

.Mobile {
  padding: 0.5em;
}

.mobile-vega-logo {
  width: 50vw;
  margin-top: 4em;
}

h3 {
  font-weight: bold;
}
